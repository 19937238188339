/*** 
Fontawesome Demo
***/

.fontawesome-icon-list {
	margin-top: 20px;
	margin-bottom: 50px;

	.fa-hover {
		> a {
			display: block;
			margin-bottom: 10px;
			border: 1px solid #eaeaea;
			padding: 5px 10px;

			> i {
				width: 20px;
				font-size: 18px;
				margin-right: 5px;
			}
		}
	}
}

/***
Simple Line Icons Demo
***/

.simplelineicons-demo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%;
}
.simplelineicons-demo .item-box .item {
  border: 1px solid #eaeaea;
  background-color: #fff;
  color: #33383e;
  display: inline-block;
  padding: 10px;
  width: 100%;
  vertical-align: center;
}
.simplelineicons-demo .item-box .item span {
  font-size: 22px;
}

.customline-demo.simplelineicons-demo {

   .item-box a {
   	  display: inline-block;
   }

  .c-content-line-icon {
  	float: left;
  	margin-top: -4px;
  	margin-right: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .simplelineicons-demo .item-box {
    width: 24.333%;
  }
}

/*** 
Glyphicons Demo
***/
.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicon-class {
  text-align: center;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 16px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
}

.glyphicons-demo ul li:hover {
   
}

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%;
  }
}
// Revolution Slider

.tp-banner-container {
	position: relative;
	margin-top: 100px;
	
	&.tp-fullscreen {
		height: 1100px;
	}
}

.tp-banner-container .fulllscreenvideo.tp-videolayer {
	left: 0px !important;
}

// Main container
.tp-banner {
	display: none;   
	width: 100%;
	position: relative;

	iframe {
		border: 0 !important;
	}
}

// slider pagination bullets
.tp-bullets {
	&.round {
		> .tp-bullet {   
			background: #e3e7ee;
			@include border-radius(10px);  
			width: 12px;
			height: 12px;

			&.selected{
				background: #32c5d2;
			}
			&:hover {
				background: #c4ccd8;
			}
		}
	}
}

// slider navigation arrows
.tparrows {

	&.c-tparrows-hide {
		display: none;
	}

	&.tp-leftarrow,
	&.tp-rightarrow {
		background: none;
		font-family: FontAwesome;
		font-weight: 100;
	}

	&.tp-leftarrow {
		background-position: 0px 3600%;
		&:before{
			content:'';
		}
	}

	&.tp-rightarrow {
		background-position: 0px 3700%;
		&:before{
			content:'';
		}
	}

	&.circle {
		background-image: url(../img/content/line-icons/white.png);
  		display: inline-block;
  		width: 60px;
  		height: 60px;
  		background-size: cover;
		transition: all 0.3s;
		color: #ffffff; 		
		@include opacity(0.3);

		&.hidearrows {
			@include opacity(0);
		}

		&:hover {
			transition: all 0.3s;
			@include opacity(1);
		}
	}
}

.tp-banner-container.c-arrow-dark {
	.tparrows {
		&.circle {
			background-image: url(../img/content/line-icons/dark1.png);
		}
	}
}

.tp-banner-container.c-arrow-darken {
	.tparrows {
		&.circle {
			background-image: url(../img/content/line-icons/dark3.png);
		}
	}
}

// slider loader
.tp-loader.spinner2 {
	background-color: #999DA7;
}


// BEGIN: Mobile Mode

@media (max-width: $screen-sm-max) { /* 991px */
	.tp-banner-container {
		margin-top: 65px;
		&.tp-fullscreen {
			height: auto;
		}
	}

	.tp-banner-container.tp-fullscreen-mobile {
		height: 800px;
	}
}  

// END
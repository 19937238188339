// Paddings
$default-padding:     						6px 20px 4px 20px !default;
$default-padding-md:    					9px 20px 7px 20px !default;
$default-padding-lg:    					11px 26px 9px 26px !default;
$default-padding-xlg:    					16px 44px 14px 44px !default;
$default-padding-sm:    					6px 18px 4px 18px !default;
$default-padding-xs:       					1px 8px 1px 8px !default;

$uppercase-padding:     					7px 20px 5px 20px !default;
$uppercase-padding-md:    					10px 20px 9px 20px !default;
$uppercase-padding-lg:    					12px 26px 11px 26px !default;
$uppercase-padding-xlg:    					20px 44px 15px 44px !default;
$uppercase-padding-sm:    					6px 18px 4px 18px !default;
$uppercase-padding-xs:       				2px 8px 0px 8px !default;

$default-border-2x-padding:     			6px 20px 4px 20px !default;
$default-border-2x-padding-md:     			8px 20px 6px 20px !default;
$default-border-2x-padding-lg:    			10px 26px 8px 26px !default;
$default-border-2x-padding-xlg:    			16px 44px 14px 44px !default;
$default-border-2x-padding-sm:    			5px 18px 3px 18px !default;
$default-border-2x-padding-xs:       		0px 8px 1px 8px !default;

$uppercase-border-2x-padding:     			6px 20px 4px 20px !default;
$uppercase-border-2x-padding-md:     		10px 20px 8px 20px !default;   
$uppercase-border-2x-padding-lg:    		12px 26px 12px 26px !default;  
$uppercase-border-2x-padding-xlg:    		18px 44px 16px 44px !default;
$uppercase-border-2x-padding-sm:    		5px 18px 3px 18px !default;
$uppercase-border-2x-padding-xs:       		1px 8px 0px 8px !default;

// Buttons sizes
.btn {
	outline: none !important;
	-webkit-transition:all 0.2s ease-out;
	-moz-transition:all 0.2s ease-out;
	-o-transition:all 0.2s ease-out;
	-ms-transition:all 0.2s ease-out;

	padding: $default-padding;
	font-size: 16px;

	> i {
		margin-right: 5px;
		text-align: center;
		font-weight: 300;
	}

	&.btn-sm {
		padding: $default-padding-sm;

		> i {
			margin-right: 5px;
			font-size: 14px; 
		}
	}

	&.btn-md {
		padding: $default-padding-md;
	}

	&.btn-xs {
		padding: $default-padding-xs;

		> i {
			margin-right: 3px;
			font-size: 14px; 
		}
	}

	&.btn-lg {
		padding: $default-padding-lg;
	}

	&.btn-xlg {
		padding: $default-padding-xlg;
	}
}

.btn.c-btn-border-2x {
	border-width: 2px;

	padding: $default-border-2x-padding;

    &.btn-sm {
		padding: $default-border-2x-padding-sm;
	}  

	&.btn-xs {
		padding: $default-border-2x-padding-xs;
	}

    &.btn-md {
		padding: $default-border-2x-padding-md;
	}

	&.btn-lg {
		padding: $default-border-2x-padding-lg;
	}

	&.btn-xlg {
		padding: $default-border-2x-padding-xlg;
	}

	&.c-btn-uppercase {
		padding: $uppercase-border-2x-padding;

	    &.btn-sm {
			padding: $uppercase-border-2x-padding-sm;
		}

		&.btn-xs {
			padding: $uppercase-border-2x-padding-xs;
		}

		&.btn-md {
			padding: $uppercase-border-2x-padding-md;
		}

		&.btn-lg {
			padding: $uppercase-border-2x-padding-lg;
		}

		&.btn-xlg {
			padding: $uppercase-border-2x-padding-xlg;   
		}
	}
}

.btn.c-btn-border-1x {
	border-width: 1px;
}

.btn + .btn {
	margin-left: 10px;
}

// Buttons With Icons, Captions and Sub Title

.btn.c-btn-icon {
	min-height: 56px;
	padding: 0;
	padding: 5px 20px 5px 20px;
  
	> i {
		@include border-radius(3px 0 0 3px);
		margin: -5px 0 0 -20px; 
		float: left;
		display: block;
		position: absolute;
		height: 56px;
		width: 56px;
		font-size: 32px;
		vertical-align: middle;
		padding-top: 10px;
	}

	> .c-helper {
		margin: 4px 20px -2px 55px;
		text-align: left;
		display: block;
		font-size: 14px;
	}

	> .c-caption {
		text-align: left;
		margin: 0px 20px 0px 55px;
		display: block;		
		font-size: 18px;
		font-weight: 500; 
	}
}

// Circle buttons

.c-btn-circle {
	border-radius: 30px !important;
}

// Buttons fonts

.c-btn-bold {
	font-weight: bold;
}

.c-btn-sbold {
	font-weight: 500;
}

.c-btn-square {
	@include border-radius(0);
}

.c-btn-uppercase {
	text-transform: uppercase;

	&.btn {
		font-size: 14px;
		padding: $uppercase-padding;
	}

	&.btn-md {
		font-size: 16px;
		padding: $uppercase-padding-md;
	}

	&.btn-lg {
		font-size: 16px;
		padding: $uppercase-padding-lg;
	}

	&.btn-xlg {
		font-size: 18px;
		padding: $uppercase-padding-xlg;
	}

	&.btn-sm {
		padding: $uppercase-padding-sm;
	}

	&.btn-xs {
		padding: $uppercase-padding-xs;
	}
}

// Bootstrap Button Variants
// button-variant($color, $background, $border, $color-hover, $background-hover, $border-hover)

.btn-default {
	@include button-variant($brand-default, #ffffff, #e1e6ee, #ffffff, #8998b5, #8998b5);
}

.btn-primary {
	@include button-variant(#ffffff, $brand-primary, $brand-primary, #ffffff, darken($brand-primary, 10%), darken($brand-primary, 10%));
}

.btn-success {
	@include button-variant(#ffffff, $brand-success, $brand-success, #ffffff, darken($brand-success, 10%), darken($brand-success, 10%));
}

.btn-danger {
	@include button-variant(#ffffff, $brand-danger, $brand-danger, #ffffff, darken($brand-danger, 10%), darken($brand-danger, 10%));
}

.btn-warning {
	@include button-variant(#ffffff, $brand-warning, $brand-warning, #ffffff, darken($brand-warning, 10%), darken($brand-warning, 10%));
}

.btn-info {
	@include button-variant(#ffffff, $brand-info, $brand-info, #ffffff, darken($brand-info, 10%), darken($brand-info, 10%));
}

@each $name, $colors in $component-colors {
.c-btn-#{$name} {
    border-color: map-get($colors, color);
	border-width: 1px;

    @include button-variant(
    	map-get($colors, inverse),  map-get($colors, color), map-get($colors, color), 
    	map-get($colors, inverse), darken(map-get($colors, color), 8%), darken(map-get($colors, color), 8%)
    );
}
}

// button-variant($color, $background, $border, $color-hover, $background-hover, $border-hover)

@each $name, $colors in $component-colors {
.c-btn-border-1x.c-btn-#{$name} {
    border-color: map-get($colors, color);

	&.c-btn-border-opacity-04 {
		border-color: rgba(map-get($colors, color), 0.4);
	}

    @include button-variant(map-get($colors, color), none, map-get($colors, color), map-get($colors, inverse), map-get($colors, color), map-get($colors, color));
}
}

@each $name, $colors in $component-colors {
.c-btn-border-2x.c-btn-#{$name} {
    border-color: map-get($colors, color);

    @include button-variant(map-get($colors, color), none, map-get($colors, color), map-get($colors, inverse), map-get($colors, color), map-get($colors, color));
}
}

@each $name, $colors in $component-colors {
.btn.c-btn-icon.c-btn-#{$name} {
    background: map-get($colors, color);
	border-color: map-get($colors, color);
	color: map-get($colors, inverse);

	> i {
		background: darken(map-get($colors, color), 5%);
		color: map-get($colors, muted);
	}     

	> .c-helper {
		color: map-get($colors, muted);  
	}

	&:hover,
	&:focus,
	&.focus,
	&:active,
	&.active { 
	    color: map-get($colors, inverse);  
	    background: darken(map-get($colors, color), 5%);
	    border-color: darken(map-get($colors, color), 5%);  

	    > i {
	    	background: darken(darken(map-get($colors, color), 3%), 5%);
	    }
	}
}
}

// Border opacity for selected colors:

.btn.c-btn-border-1x.c-btn-white,
.btn.c-btn-border-2x.c-btn-white {
	border-color: rba(#ffffff, 0.9);
}

// Owl Carousel Customizations

.owl-carousel {
	.owl-controls {
		margin-top: 60px;

		.owl-dot {
			width: 12px;
			height: 12px;
			margin: 5px 7px;
			display: inline-block;
			@include border-radius(50%);
		}
	}

	&.c-owl-nav-center{

		.owl-controls{
			text-align: center;
		}
	}

	&.owl-bordered {
		margin-top: 30px;

		.owl-controls {
			margin-top: 20px;
		}

		.owl-wrapper-outer {
			border: 1px solid $general-border-color;
		}

		.owl-item {
			border-right: 1px solid $general-border-color;

			&:last-child {
				border-right: 0;
			}
		}
	}

	&.owl-reset-space {
		&:not(.owl-single):not(.owl-bordered) {
			margin: 0;
			width: 100%;

			.owl-wrapper {
				.owl-item {
					padding: 0;
				}
			}
		}
	}

	&.owl-small-space {
		&:not(.owl-single):not(.owl-bordered) {
			margin: 0;
			width: 100%;

			.owl-wrapper {
				.owl-item {
					padding: 5px;
				}
			}
		}
	}
}

.owl-theme:not(.owl-single):not(.owl-bordered) {
	margin-left: -30px;
	width: calc(100% + 60px);

	.owl-wrapper, .owl-stage-outer {
		.owl-item{
			padding: 0 30px;
		}
	}
}

.owl-theme.owl-theme-white {
	.owl-pagination {
		.owl-page {
			span {
				background: #ffffff;
				@include opacity(0.5);
			}

			&.active {
				span {
					background: #ffffff;
					@include opacity(1);
				}
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	/* 767px */

	.owl-theme:not(.owl-single):not(.owl-bordered) {
		margin-left: -10px;
		width: calc(100% + 20px);

		.owl-wrapper {

			.owl-item {
				padding: 0 10px;
			}
		}
	}
}